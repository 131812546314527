// ==UserScript==
// @name GitHub Emoji on Linux
// @description Convinces GitHub that Linux supports emoji by removing Linux from the user-agent.
// @version 2
// @namespace https://userscripts.kevincox.ca
// @match https://github.com/*
// @run-at document-start
// @grant unsafeWindow
// ==/UserScript==

Object.defineProperty(unsafeWindow.navigator, "userAgent", {
	value: navigator.userAgent.replace("Linux", "LinMoji") + " (https://github.com/github/g-emoji-element/issues/2)",
});

// GitHub also tries to override our preferred emoji font, undo that.
document.head.insertAdjacentHTML("beforeend", `<style>g-emoji{font-family:initial!important}</style>`);
